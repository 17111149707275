import { Col, Row } from '@ahmdigital/ui';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import PriceDisclaimer from '../../../../components/price-disclaimer';
import ProductPropType from '../../../../types/product';

const StyledCol = styled(Col)`
  margin: 3rem auto;
`;

const StyledDisclaimer = styled(PriceDisclaimer)`
  text-align: center;
`;

type HeroPriceDisclaimerProps = {
  product?: ProductPropType;
};

const HeroPriceDisclaimer = ({ product }: HeroPriceDisclaimerProps) => (
  <Row>
    <StyledCol sm={12} df={10}>
      {/* @ts-expect-error - Automatic, Please fix when editing this file */}
      <StyledDisclaimer bold forProducts={isEmpty(product) ? undefined : [product]} highlightIncome />
    </StyledCol>
  </Row>
);

export default HeroPriceDisclaimer;
