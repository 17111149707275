import { defaultsAll, isArray, map, pick } from 'lodash/fp';

import { CHANGE_EFFECTIVE_DATE, PRICE_LOAD, PRICE_LOAD_FAIL, PRICE_LOAD_SUCCESS } from '../action-types';
import getCustomerAgeBracket from '../../utils/get-customer-age-bracket';
import PriceService from '../../models/price';
import selectCustomer from '../../selectors/select-customer';

export type PriceActionsType = {
  changeEffectiveDate: (useFutureEffectiveDate?: boolean) => void;
  load: (productIds: any, data?: {}, options?: {}) => (dispatch: any, getState: any) => any;
};

// @ts-expect-error - Automatic, Please fix when editing this file
function load(productIds, data = {}, options = {}) {
  // @ts-expect-error - Automatic, Please fix when editing this file
  return (dispatch, getState) => {
    const globalState = getState();
    const customer = selectCustomer(data) || selectCustomer(globalState) || {};
    const ageBracket = getCustomerAgeBracket(customer);

    const productIdsArray = isArray(productIds) ? productIds : [productIds];
    const conditions = pick(
      ['dependants', 'incomeTier', 'members', 'rebate', 'state', 'scale', 'starts', 'payment'],
      data,
    );

    const conditionsWithDefaults = defaultsAll([conditions, { ageBracket }, customer]);
    const keys = map((productId) => PriceService.getKey(productId, conditionsWithDefaults, options), productIdsArray);

    return dispatch({
      keys,
      // @ts-expect-error - Automatic, Please fix when editing this file
      promise: (api) => PriceService.loadPrices(api, productIdsArray, conditionsWithDefaults, options),
      types: [PRICE_LOAD, PRICE_LOAD_SUCCESS, PRICE_LOAD_FAIL],
    });
  };
}

function changeEffectiveDate(useFutureEffectiveDate: boolean = false) {
  return {
    type: CHANGE_EFFECTIVE_DATE,
    useFutureEffectiveDate,
  };
}

const PriceActions: PriceActionsType = {
  changeEffectiveDate,
  load,
};

export default PriceActions;
