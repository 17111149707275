import { get, isNil } from 'lodash/fp';

import extractRelativePath from '../../../../elements/header/utils/extract-perk-url';

// @ts-expect-error - Automatic, Please fix when editing this file
const buildCtaProps = (perk) => {
  let ctaText = get('descriptionTeaser', perk);
  if (isNil(ctaText)) {
    ctaText = get('urlObject.name', perk);
  }

  return {
    ctaText,
    ctaUrl: extractRelativePath(perk),
  };
};

export default buildCtaProps;
