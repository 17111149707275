import { compact, isEmpty, map } from 'lodash/fp';
import { IconList, PortableText, theme } from '@ahmdigital/ui';
import generatePortableText from '@ahmdigital/logic/lib/content/utils/generate-portable-text';
import React from 'react';
import styled from 'styled-components';

import type { Subtext } from '../../../../../../components/offers/tile/utils/get-content-for-offer';

const PortableTextForList = styled(PortableText)`
  margin-left: 6px;

  @media (min-width: ${theme.media.sm}) {
    max-width: 47vw; // This prevents overlap with the hero background image
  }
`;
const PortableTextForTerms = styled(PortableTextForList)`
  margin-top: 6px;
`;

// @ts-expect-error - Automatic, Please fix when editing this file
const subtextToListItems = (subtextValue) => {
  if (isEmpty(subtextValue)) return null;
  return {
    fill: theme.colors.green,
    icon: 'tick-circle',
    text: <PortableTextForList source={generatePortableText({ value: subtextValue })} />,
  };
};

type RewardsListProps = {
  sublist: Subtext;
  // @ts-expect-error - Automatic, Please fix when editing this file
  ends: { value };
};

const RewardsList = ({ sublist, ends }: RewardsListProps) => (
  <>
    <IconList items={compact(map(subtextToListItems, sublist.value))} variant="simple" />
    <PortableTextForTerms source={generatePortableText(ends)} />
  </>
);

export default RewardsList;
