import { compact, isEmpty, isPlainObject, reject, sample, size } from 'lodash/fp';
import React from 'react';

import { getCookieForMembers } from '../../../../utils/cookie-for-members';
import filterOffersByProductType from '../../../../utils/filter-offers-by-product-type';
import OfferTile from '../../../../components/offers/tile';
import PerkTile from '../../components/perk-tile';
import ProductPropType from '../../../../types/product';
import type { Offer, Placeholder } from '../../../../components/offers/types';

export type GetOfferOrPerkTileProps = {
  isHomepageOfferBannerEnabled: boolean;
  isPartnerReferral: boolean;
  lastViewedProduct?: ProductPropType;
  offerProps: {
    activeOffers?: Offer[];
    placeholders?: Placeholder[];
  };
  serverCookieValue: string;
};

const getOfferOrPerkTile = ({
  isHomepageOfferBannerEnabled,
  isPartnerReferral = false,
  lastViewedProduct,
  offerProps,
  serverCookieValue,
}: GetOfferOrPerkTileProps) => {
  const isHealthMember = isPlainObject(getCookieForMembers('health', { serverCookieValue }));
  const isCarMember = isPlainObject(getCookieForMembers('car', { serverCookieValue }));
  const isHomeMember = isPlainObject(getCookieForMembers('home', { serverCookieValue }));
  const HomeInsuranceTile = <PerkTile perkId="ahm-home-insurance" />;
  const CarInsuranceTile = <PerkTile perkId="ahm-car-insurance" />;

  if (isHealthMember || isPartnerReferral) {
    if (isCarMember && isHomeMember) {
      return null;
    }

    if (isCarMember) {
      return HomeInsuranceTile;
    }

    if (isHomeMember) {
      return CarInsuranceTile;
    }

    return sample(compact([HomeInsuranceTile, CarInsuranceTile]));
  }

  const heading = 'Check out our offers';
  const activeOffers = offerProps?.activeOffers;
  // If there is an offer being shown in the hero section of the home page, then strip that offer out
  // from the offers that will be shown in the home page offer tile.
  if (isHomepageOfferBannerEnabled && !isEmpty(activeOffers)) {
    // If there is currently only one active offer, then it's already shown in the hero section
    // so no need to show it again
    if (size(activeOffers) === 1) {
      return null;
    }
    const lastViewedProductType = lastViewedProduct?.type;
    // @ts-expect-error - Automatic, Please fix when editing this file
    const heroOffer = filterOffersByProductType(lastViewedProductType, activeOffers);
    const offersWithoutHero = reject({ id: heroOffer?.id }, activeOffers);
    // @ts-expect-error - Automatic, Please fix when editing this file
    return <OfferTile heading={heading} overrideOffers={offersWithoutHero} placeholders={offerProps?.placeholders} />;
  }
  return <OfferTile heading={heading} />;
};

export default getOfferOrPerkTile;
