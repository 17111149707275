import { TARGET_SEGMENT } from '../../../src/client/cms/constants';
import useGetActivePromoOffer from '../../../src/client/components/offers/logic/use-get-active-promo-offer';
import useGetAllPlaceholders from '../../../src/client/components/offers/logic/use-get-all-placeholders';

// @ts-expect-error - Automatic, Please fix when editing this file
const useGetOffersContent = ({ shouldSkip }) => {
  const { activeOffers, loading: isLoadingOffers } = useGetActivePromoOffer({
    skip: shouldSkip,
    targetSegment: TARGET_SEGMENT.LOOKING_FOR_PRIVATE_HEALTH_INSURANCE,
  });
  const { placeholders, loading: isLoadingPlaceHolders } = useGetAllPlaceholders({
    skip: shouldSkip,
  });

  return {
    activeOffers,
    loading: isLoadingOffers || isLoadingPlaceHolders,
    placeholders,
  };
};

export default useGetOffersContent;
