import { compact, every, flow, isEmpty, isPlainObject, map, pick, some } from 'lodash/fp';

import getImageAttributes from '../../../../../../cms/utils/get-image-attributes';
import getLinkForExplainedTerm from '../../../../../../utils/get-link-for-explained-term';
import type { ImageObjectType } from '../../../../../../types/sanity';
import type { PortableTextType } from '../../../../../../types/sanityInternal';

export type ItemType = {
  _id: string;
  __typename?: string;
  name: string;
  sloganRaw?: PortableTextType[];
  descriptionRaw?: PortableTextType[];
  imageObject?: ImageObjectType;
  summaryRaw?: PortableTextType;
};

const mapContent = (items: ItemType[]) =>
  flow(
    map((item: ItemType) => {
      if (!isPlainObject(item)) {
        return null;
      }

      const { _id, __typename, name, sloganRaw, descriptionRaw, imageObject, summaryRaw } = pick(
        ['_id', '__typename', 'name', 'sloganRaw', 'descriptionRaw', 'imageObject', 'summaryRaw'],
        item,
      );

      const mantantoryFieldsMissing =
        __typename === 'ExplainedTerm'
          ? some(isEmpty, [_id, sloganRaw, imageObject, summaryRaw])
          : some(isEmpty, [_id, descriptionRaw]) && every(isEmpty, [name, sloganRaw]);
      if (mantantoryFieldsMissing) {
        return null;
      }

      // @ts-expect-error - Automatic, Please fix when editing this file
      const image = getImageAttributes({ height: 240, imageObject });

      const ctaObject = compact([getLinkForExplainedTerm(item)]);

      return {
        _id,
        ctas: ctaObject,
        image,
        subtitle: __typename === 'ExplainedTerm' ? summaryRaw : descriptionRaw,
        title: isEmpty(sloganRaw) ? name : sloganRaw,
      };
    }),
    compact,
  )(items);

export default mapContent;
