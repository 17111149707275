import { chunk, isArray, isEmpty, map, size } from 'lodash/fp';

import { TILE_VARIANTS } from '../../ahm-constants/ui';

// @ts-expect-error - Automatic, Please fix when editing this file
const sortTiles = (tiles, tileVariant) => {
  if (isEmpty(tiles) || !isArray(tiles)) {
    return [];
  }

  const rows = chunk(2)(tiles);
  return map((row) =>
    map((tile) => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (size(row) === 1) {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return { ...tile, variant: TILE_VARIANTS.FULL_WIDTH };
      }
      // @ts-expect-error - Automatic, Please fix when editing this file
      return { ...tile, variant: tileVariant };
      // @ts-expect-error - Automatic, Please fix when editing this file
    })(row),
  )(rows);
};

export default sortTiles;
