import { flatMap, getOr, includes, map } from 'lodash/fp';

import PREMIUM_CHANGE from '../../features/premium-change/constants';
import PriceService from '../../models/price';
import UrlService from '../../models/url';

// @ts-expect-error - Automatic, Please fix when editing this file
const serviceMapper = (feature) => map(({ id, name }) => ({ id, name }), feature.services);

const prepareProductForProductTile =
  // @ts-expect-error - Automatic, Please fix when editing this file


    ({ customer, frequency, prices, mlsAmount, useFutureEffectiveDate }) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    (product) => {
      if (!product) {
        return null;
      }

      const {
        id,
        additionalDetails,
        charges,
        content,
        ctaText,
        features,
        hospitalTier,
        name,
        rank,
        type,
        ctaProductId,
      } = product;

      const date = useFutureEffectiveDate ? PREMIUM_CHANGE.FUTURE_EFFECTIVE_DATE : null;

      const price = PriceService.getPrice(prices, id, customer, { date });

      const priceObject = price ? PriceService.getAmountAsObject(price, frequency) : undefined;
      const saving =
        mlsAmount && priceObject
          ? Math.max(mlsAmount - parseFloat(`${priceObject.dollars}.${priceObject.cents}`), 0)
          : undefined;

      const hospitalFeatured = getOr([], 'hospital.featured', product);
      const hospitalServices = flatMap(serviceMapper, hospitalFeatured);

      return {
        additionalDetails,
        charges,
        content,
        ctaText,
        features,
        frequency,
        hospitalTier,
        id,
        isAvailable: includes(customer.scale, product.scales.supported),
        name,
        price: priceObject,
        rank,
        saving,
        scale: customer.scale,
        services: hospitalServices,
        type,
        url: UrlService.getProductUrl(ctaProductId || id),
      };
    };

export default prepareProductForProductTile;
export { serviceMapper };
