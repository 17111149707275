import { Button, Col, H1, Row, SpacerWrapper } from '@ahmdigital/ui';
import { get, map } from 'lodash/fp';
import Icon from '@ahmdigital/icons-web';
import React from 'react';
import styled from 'styled-components';

import HeroSectionTile from '../hero-section-tile';
import type { DiversifiedHeroProps, ListItems, TileContent } from '../../types';

const StyledTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.media.md}) {
    flex-direction: row;
  }
`;

const StyledH1 = styled(H1)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  flex: 3;
  justify-content: flex-start;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.media.md}) {
    flex: 1;
    justify-content: space-between;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 0.5rem;
`;

const StyledHeroSectionTile = styled(HeroSectionTile)`
  margin: 1.5rem;
  word-wrap: normal;
`;

const StyledHR = styled.hr`
  border: none;
  border-top: 2px dotted black;
`;

const StyledSpacerWrapper = styled(SpacerWrapper)`
  margin: 0;

  @media (min-width: ${({ theme }) => theme.media.md}) {
    margin: 0 2rem;
  }
`;

const GridWrapper = styled(SpacerWrapper)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 3rem;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${({ theme }) => theme.media.lg}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const getTilesContent = (tiles: ListItems[]) =>
  map(
    (insuranceType) => ({
      alt: get('tileImage.caption', insuranceType),
      buttonLink: get('rootUrl', insuranceType),
      buttonName: 'Get a quote',
      desc: get('offer.[0].description', insuranceType),
      image: get('tileImage.image.asset.url', insuranceType),
      link: get('termsAndConditionsUrl', insuranceType),
      linkName: 'See terms',
      title: get('name', insuranceType),
    }),
    tiles,
  );

const DiversifiedHero = ({ name, urlObject, listItems }: DiversifiedHeroProps) => {
  // @ts-expect-error - Automatic, Please fix when editing this file
  const tilesContent = getTilesContent(listItems);

  const hasCta = get('url', urlObject) && get('name', urlObject);

  return (
    <Row isBoxed>
      <Col df>
        <StyledSpacerWrapper variant={['marginBottomSpacer10', 'marginTopSpacer4']}>
          <StyledTitleSection>
            <StyledH1>{name}</StyledH1>
            {hasCta && (
              <StyledButtonWrapper>
                <StyledButton to={get('url', urlObject)} variant="purple">
                  {get('name', urlObject)}
                  <StyledIcon name="chevron-right" fill="CurrentColor" />
                </StyledButton>
              </StyledButtonWrapper>
            )}
          </StyledTitleSection>
          <StyledHR />
        </StyledSpacerWrapper>
        <StyledSpacerWrapper variant={['marginBottomSpacer3', 'marginTopSpacer4']}>
          <GridWrapper>
            {map((tileContent: TileContent) => (
              <StyledHeroSectionTile {...tileContent} key={tilesContent.indexOf(tileContent)} />
            ))(tilesContent as TileContent[])}
          </GridWrapper>
        </StyledSpacerWrapper>
      </Col>
    </Row>
  );
};

export default DiversifiedHero;
